import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { validateDateRange } from "../../../../../helpers/validationHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import homeStore from "../../../../../store/home/homeStore";
import { StatisticFiltersTypes } from "../../../../../types/statisticTypes";
import {
    ButtonStickyContainer,
    FormContainer,
    HeaderContainer,
    HeaderTitle,
} from "../../../../filters/FiltersModalComponents";
import Button from "../../../../ui/button/Button";
import { TextButton } from "../../../../ui/button/ButtonStyles";
import { WarningText } from "./ChartFiltersComponents";
import FieldsList from "./FieldsList";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";

interface ChartsFiltersProps {
    closeModal: () => void;
}

const ChartsFilters = ({ closeModal }: ChartsFiltersProps) => {
    const { t, translate } = useTranslation();

    const initialValues: StatisticFiltersTypes = { ...homeStore.filters };

    const formik = useFormik<StatisticFiltersTypes>({
        initialValues,
        enableReinitialize: true,

        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),

        initialTouched: {
            created_to: !!homeStore.filters?.created_to,
        },

        onSubmit: async (fields) => {
            homeStore.updateFilters(fields);
            await homeStore.fetchData();

            if (homeStore.isFetchRequestSuccess) {
                closeModal();
            } else {
                snackbarStore.add({ variant: "error", text: homeStore.fetchErrors });
            }
        },
    });

    const isSubmitButtonDisabled = (!formik.values.created_from || !formik.values.created_to)
        || (!formik.values.partners && !formik.values.services && !formik.values.processings);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        homeStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.filters.title}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit} $scrollable>
                <FieldsList formik={formik} />

                <ButtonStickyContainer>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitButtonDisabled}
                        isLoading={homeStore.loading}
                    >
                        {t.filters.apply}
                    </Button>
                    {isSubmitButtonDisabled && (
                        <WarningText>{t.home.filtersModal.warning}</WarningText>
                    )}
                </ButtonStickyContainer>
            </FormContainer>
        </>
    );
};

export default observer(ChartsFilters);
