import { QueriesFiltersTypes, QueriesTypes } from "../../types/queriesTypes";
import request from "../request";

type GetQueriesRequest = QueriesFiltersTypes & {
    limit?: number;
    offset?: number;
    ordering?: string;
}

interface GetQueriesResponse {
    message: string;
    response: {
        count: number;
        results: QueriesTypes[];
    };
}

export const getQueries = (params?: GetQueriesRequest) => request<GetQueriesResponse>({
    method: "GET",
    uri: "queries/",
    params,
});
