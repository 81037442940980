export function removeEmptyFields<T extends Record<string, any>>(
    fields: T,
    ...exceptionFields: string[]
): Partial<T> {
    const filteredFields: Partial<T> = {};

    (Object.keys(fields) as Array<keyof T>).forEach((key) => {
        const field = fields[key];
        const isValue = Array.isArray(field) ? field.length : field;

        if (exceptionFields.includes(key as string) || isValue) {
            filteredFields[key] = field;
        }
    });

    return filteredFields;
}
