import { Fragment, ReactNode } from "react";

import { splitHeaderName } from "../../helpers/secondHeaderHelper";
import useTranslation from "../../hooks/useTranslation";
import {
    ActionsAndTabsContainer,
    ActionsContainer,
    ActiveTitle,
    ComponentWrapper,
    Container,
    SubTitle,
    SubTitleActive,
    Title,
    TitleContainer,
} from "./SecondHeaderComponents";

interface SecondHeaderProps {
    title: string;
    tabs?: ReactNode;
    subTitle?: { from: string, to: string };
    actionsContent?: { content: ReactNode, key: string, isDisplayed?: boolean }[];
    isMargin?: boolean;
    isCapitalized?: boolean;
}

const SecondHeader = ({
    title,
    tabs,
    actionsContent,
    subTitle,
    isMargin = true,
    isCapitalized = true,
}: SecondHeaderProps) => {
    const { t } = useTranslation();
    const splittedName = splitHeaderName(title);

    return (
        <ComponentWrapper $isMargin={isMargin}>
            <Container $tabs={!!tabs}>
                <TitleContainer>
                    {splittedName.title
                        ? (
                            <>
                                <Title $isCapitalized={isCapitalized}>{splittedName.title}</Title>
                                <ActiveTitle>{splittedName.activeTitle}</ActiveTitle>
                            </>
                        )
                        : <ActiveTitle>{splittedName.activeTitle}</ActiveTitle>}
                    {subTitle ? (
                        <>
                            <SubTitleActive>{`${t.status.active} ${subTitle.from}`}</SubTitleActive>
                            <SubTitle>{`/${subTitle.to}`}</SubTitle>
                        </>
                    ) : null}
                </TitleContainer>

                <ActionsAndTabsContainer $tabs={!!tabs}>
                    {tabs}
                    <ActionsContainer>
                        {actionsContent?.map((actionContent) => {
                            const { isDisplayed } = actionContent;

                            return isDisplayed !== false && (
                                <Fragment key={actionContent.key}>
                                    {actionContent.content}
                                </Fragment>
                            );
                        })}
                    </ActionsContainer>
                </ActionsAndTabsContainer>
            </Container>
        </ComponentWrapper>
    );
};
export default SecondHeader;
