export interface IRoute {
    t: string;
    href: string;
}

export type PagesType =
    | "home"
    | "lists"
    | "employees"
    | "employee"
    | "roles"
    | "elements"
    | "entities"
    | "limits"
    | "partners"
    | "services"
    | "processing"
    | "transactions"
    | "rulesPacks"
    | "fraud"
    | "alerts"
    | "alert"
    | "notifications"
    | "groups"
    | "graphs"
    | "settings"
    | "rule"
    | "queries"
    | "query"
    | "results"

const Routes: Record<PagesType, IRoute> = {
    home: {
        t: "menu.links.home",
        href: "/",
    },
    lists: {
        t: "menu.links.lists",
        href: "/lists",
    },
    employees: {
        t: "menu.links.employees",
        href: "/employees",
    },
    employee: {
        t: "menu.links.employee",
        href: "/employees/employees",
    },
    roles: {
        t: "menu.links.roles",
        href: "/employees/roles",
    },
    limits: {
        t: "menu.links.limits",
        href: "/limits",
    },
    elements: {
        t: "menu.links.elements",
        href: "/elements",
    },
    entities: {
        t: "menu.links.entities",
        href: "/entities",
    },
    partners: {
        t: "menu.links.partners",
        href: "/entities/partners",
    },
    services: {
        t: "menu.links.services",
        href: "/entities/services",
    },
    processing: {
        t: "menu.links.processings",
        href: "/entities/processings",
    },
    transactions: {
        t: "menu.links.transaction",
        href: "/transactions",
    },
    rulesPacks: {
        t: "menu.links.rulesPacks",
        href: "/rules-packs",
    },
    rule: {
        t: "menu.links.newRule",
        href: "/rules-packs",
    },
    fraud: {
        t: "menu.links.fraud",
        href: "/fraud",
    },
    alerts: {
        t: "menu.links.alerts",
        href: "/alerts",
    },
    alert: {
        t: "menu.links.alert",
        href: "/alerts/alerts",
    },
    notifications: {
        t: "menu.links.notifications",
        href: "/alerts/notifications",
    },
    groups: {
        t: "menu.links.groups",
        href: "/alerts/tg_groups",
    },
    graphs: {
        t: "menu.links.graphs",
        href: "/graphs",
    },
    settings: {
        t: "menu.links.settings",
        href: "/settings",
    },
    queries: {
        t: "menu.links.queries",
        href: "/queries",
    },
    query: {
        t: "menu.links.query",
        href: "/queries/queries",
    },
    results: {
        t: "menu.links.results",
        href: "/queries/results",
    },
} as const;

export default Routes;

export const AuthRoutes = {
    login: {
        href: "/",
    },
    loginTFA: {
        href: "/tfa",
    },
    password: {
        href: "/password",
    },
    passwordReset: {
        href: "/password/reset",
    },
    passwordResetCheckInbox: {
        href: "/password/reset/checkinbox",
    },
    passwordChangedPassword: {
        href: "/password/change",
    },
    passwordResetConfirm: {
        href: "/password/reset/confirm",
    },
    firstLoginWelcomePage: {
        href: "/welcome",
    },
    firstLoginChangePassword: {
        href: "/password/change",
    },
    firstLoginChangedPasswordSuccess: {
        href: "/password/change/success",
    },
    firstLoginSetUpTFA: {
        href: "/tfa/setup",
    },
};
