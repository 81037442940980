import styled from "styled-components";
import { colors } from "../../styles/variables";

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const HeaderTitle = styled.h2`
    font-size: 24px;
    color: ${colors.activeText};
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
    color: ${colors.activeText};
    margin: 0;
`;

export const SectionTitle = styled.h3`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
    margin: 8px 0;
`;

export const FormContainer = styled.form<{ $scrollable?: boolean, gap?: string }>`
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.gap ? props.gap : "8px")};

    ${(props) => {
        if (props.$scrollable) {
            return `
          height: 100%;
    `;
        }
        return "";
    }}
`;

export const ScrollableFormContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 5px 5px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    > div {
        height: 56px;
        width: 310px;
    }

    .date-picker-container {
        height: max-content;
    }


`;

export const ButtonStickyContainer = styled.div`
    position: sticky;
    bottom: 0;
    padding: 38px 0 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const SubTitle = styled.h3`
    color: ${colors.activeText};
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
    margin: 8px 0 4px;
`;

export const FilterModalContainer = styled.div`
    width: 310px;
`;
