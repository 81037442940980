import styled from "styled-components";
import { colors } from "../../styles/variables";

const ComponentWrapper = styled.div<{ $isMargin: boolean }>`
    background-color: ${colors.buttonDisabledBackgroundSecondary};
    grid-area: secondHeader;
    position: sticky;
    top: 106px;
    z-index: 10;
    margin-bottom: ${(props) => (props.$isMargin ? "20px" : 0)};
    height: max-content;
`;

const Container = styled.header<{ $tabs: boolean }>`
    padding: 15px 20px;
    display: flex;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    background-color: ${colors.white};

    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${(props) => {
        if (props.$tabs) {
            return `
             flex-direction: column;
             align-items: flex-start;
    `;
        }
        return "";
    }};
`;

const ActionsAndTabsContainer = styled.section<{ $tabs: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${(props) => (props.$tabs ? "100%" : "fit-content")};
`;

const ActionsContainer = styled.section`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

const Title = styled.h2<{ $isCapitalized?: boolean }>`
    color: ${colors.textDefault};
    font-size: 36px;
    font-weight: 600;
    line-height: 105%;
    margin: 0;
    text-transform: ${(props) => (props.$isCapitalized ? "capitalize" : "none")};
    white-space: nowrap;
`;

const ActiveTitle = styled(Title)`
    color: ${colors.textPrimary};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    // TODO: Нужно переделать под адаптивную ширину
    width: calc(50vw - 260px);
`;

const SubTitle = styled.h4`
    color: ${colors.textDefault};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    margin: 0;
`;

const SubTitleActive = styled(SubTitle)`
    color: ${colors.activeText};
    margin-left: 16px;
`;

export {
    SubTitleActive,
    SubTitle,
    ActiveTitle,
    TitleContainer,
    Title,
    ActionsContainer,
    ActionsAndTabsContainer,
    ComponentWrapper,
    Container,
};
