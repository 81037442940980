import { colors } from "../../../../../../styles/variables";

interface CustomTickProps {
    x?: number;
    y?: number;
    payload?: {
        value: string;
    };
    index?: number;
}

const ChartTick = ({
    x = 0,
    y = 0,
    payload = { value: "" },
    index = 0,
}: CustomTickProps) => {
    const isVisible = index % 2 === 1;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                textAnchor="middle"
                dy={16}
                fill={colors.tick}
                opacity={isVisible ? 1 : 0}
            >
                {payload.value}
            </text>
        </g>
    );
};

export default ChartTick;
