import { useRef } from "react";

import routes, { PagesType } from "../constants/routes";
import alertsStore from "../store/alerts/alerts/alertsStore";
import notificationsStore from "../store/alerts/notifications/notificationsStore";
import tgGroupsStore from "../store/alerts/tgGroups/tgGroupsStore";
import elementStore from "../store/element/elementStore";
import partnerStore from "../store/entities/partner/partnerStore";
import processingStore from "../store/entities/processing/processingStore";
import serviceStore from "../store/entities/service/serviceStore";
import homeStore from "../store/home/homeStore";
import limitStore from "../store/limit/limitStore";
import listStore from "../store/list/listStore";
import queriesStore from "../store/queries/queries/queriesStore";
import rulesPackPartnersStore from "../store/rulesPack/rulesPackPartnersStore";
import rulesPackServicesStore from "../store/rulesPack/rulesPackServicesStore";
import rulesPackStore from "../store/rulesPack/rulesPackStore";
import transactionStore from "../store/transaction/transactionStore";
import employeeStore from "../store/user/employee/employeeStore";

type StoragesKeys = Extract<PagesType,
    | "/"
    | "lists"
    | "limits"
    | "elements"
    | "entities"
    | "transactions"
    | "alerts"
    | "queries"
    | "employees"> & "rules-packs";

type StoragesValues =
    | typeof homeStore
    | typeof listStore
    | typeof limitStore
    | typeof elementStore
    | typeof queriesStore
    | [typeof partnerStore, typeof processingStore, typeof serviceStore]
    | typeof transactionStore
    | typeof rulesPackStore
    | [typeof alertsStore, typeof notificationsStore]
    | typeof employeeStore;

type StoragesType = {
    [key in StoragesKeys]: StoragesValues;
};

const STORAGES: StoragesType = {
    [routes.home.href]: homeStore,
    [routes.lists.href]: listStore,
    [routes.limits.href]: limitStore,
    [routes.elements.href]: elementStore,
    [routes.entities.href]: [partnerStore, processingStore, serviceStore],
    [routes.transactions.href]: transactionStore,
    [routes.rulesPacks.href]: [rulesPackStore, rulesPackPartnersStore, rulesPackServicesStore],
    [routes.alerts.href]: [alertsStore, notificationsStore, tgGroupsStore],
    [routes.queries.href]: queriesStore,
    [routes.employees.href]: employeeStore,
};

export function useClearStorage() {
    const previousRootLocationRef = useRef<string | null>(null);

    const clearStore = (store: StoragesValues) => {
        if (Array.isArray(store)) {
            store.forEach((currentStore) => currentStore?.clearPersistedData());
        } else store?.clearPersistedData();
    };

    const clearPreviousLocationStorage = (pathname: string) => {
        const previousLocation = previousRootLocationRef.current;
        if (previousLocation) {
            const isCurrentLocationChildOfRoot = pathname.includes(previousLocation)
                || previousLocation.includes(pathname);
            if (!isCurrentLocationChildOfRoot) {
                const currentStore = STORAGES[previousLocation as StoragesKeys];
                clearStore(currentStore);
            }
        }
        const [, currentLocation] = pathname.split("/");
        previousRootLocationRef.current = `/${currentLocation}`;
    };

    const clearAllStorages = () => {
        Object.values(STORAGES).forEach((store) => {
            clearStore(store as StoragesValues);
        });
    };

    return { clearPreviousLocationStorage, clearAllStorages };
}
