import {
    action,
    computed,
    flow,
    makeObservable,
    observable,
} from "mobx";

import activateQueries from "../../../api/queries/activateQueries";
import deactivateQueries from "../../../api/queries/deactivateQueries";
import { generateQueryId } from "../../../api/queries/generateQueryId";
import { getQueries } from "../../../api/queries/getQueries";
import { getQueryTaskId, GetQueryTaskIdResponse } from "../../../api/queries/getQueryTaskId";
import { getSelectedQuery } from "../../../api/queries/getSelectedQuery";
import patchQueries from "../../../api/queries/patchQueries";
import { postQueries } from "../../../api/queries/postQueries";
import { parseDateFromServer } from "../../../helpers";
import { FetchStatusesType } from "../../../types/fetchStatuses";
import { NewQueryType, QueriesFiltersTypes, QueriesTypes } from "../../../types/queriesTypes";
import dataStore from "../../common/dataStore";

class QueriesStore extends dataStore<QueriesTypes, QueriesFiltersTypes, NewQueryType> {
    protected fetchDataMethod = getQueries;

    protected fetchSelectedItemMethod = getSelectedQuery;

    protected patchDataMethod = patchQueries;

    protected postDataMethod = postQueries;

    protected deactivateSelectedItemsMethod = deactivateQueries;

    protected activateSelectedItemsMethod = activateQueries;

    @observable private _queryResultId: string | null = null;

    @observable private runQueryState = FetchStatusesType.unset;

    constructor() {
        super("QueriesStore");
        makeObservable(this);
    }

    @computed
    public get queryResultId() {
        return this._queryResultId;
    }

    @computed
    public get isRunQueryLoading() {
        return this.runQueryState === FetchStatusesType.pending;
    }

    @computed
    public get isRunQuerySuccess() {
        return this.runQueryState === FetchStatusesType.success;
    }

    @computed
    public get isRunQueryFailed() {
        return this.runQueryState === FetchStatusesType.failed;
    }

    @action
    public resetQueryResultId = () => {
            this._queryResultId = null;
        };

    fetchData = flow(function* fetchData(this: QueriesStore) {
        const response = yield this.fetchWithoutSet();

        if (response.data.response.results) {
            this._data = parseDateFromServer<QueriesTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
            this._pagination.totalActiveCount = response.data.response.total_active_count;
        }
    });

    fetchSelectedItem = flow(function* fetchSelectedItem(this: QueriesStore, id: number | string) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);

        if (response?.data.response) {
            [this._selectedItem] = parseDateFromServer<QueriesTypes>(
                [response.data.response],
                "created",
            );
        }
    });

    patchSelectedItem = flow(
        function* patchData(this: QueriesStore, data: Partial<QueriesTypes>, id: number|string, fieldsList: string[]) {
            const response = yield this.patchDataWithoutSet(data, id, fieldsList);

            if (response?.data.response) {
                [this._selectedItem] = parseDateFromServer<QueriesTypes>(
                    [response.data.response],
                    "created",
                );
            }
        },
    );

    runQuery = flow(function* runQuery(this: QueriesStore, queryId: string) {
        try {
            this.runQueryState = FetchStatusesType.pending;

            const queryTaskIdResponse: GetQueryTaskIdResponse = yield getQueryTaskId(queryId);

            const queryIdResponse = yield generateQueryId(queryTaskIdResponse.data.response.task_id);

            this._queryResultId = queryIdResponse;

            this.runQueryState = FetchStatusesType.success;
        } catch (error) {
            this.runQueryState = FetchStatusesType.failed;
        }
    });
}

const queriesStore = new QueriesStore();

export default queriesStore;
