import { useState } from "react";
import BlockAllModal from "../components/BlockAllModal";
import SmallButton from "../../../../ui/button/SmallButton";
import useTranslation from "../../../../../hooks/useTranslation";
import ProtectedSmallButton from "../../../../ui/button/ProtectedSmallButton";
import { permissionsMap } from "../../../../../constants/permissions";
import { useNavigation } from "../../../../../hooks/useNavigation";

// Temporarily deactivates BlockAll button and modal component
// according to https://task.devgfs.org/browse/AF-1222 requirements
const isBlockAllAvailable = false;

export function useTabsActions() {
    const { t } = useTranslation();
    const { navigateBackward } = useNavigation();

    const [isBlacklistAllModalVisible, setBlacklistAllModalVisibility] = useState(false);
    const toggleBlacklistAllModal = () => setBlacklistAllModalVisibility((prev) => !prev);

    const handleNavigate = () => navigateBackward();

    const BlockAllButtonComponent = (
        <ProtectedSmallButton
            permission={permissionsMap.elements.add}
            title={t.transactions.text.blockAll}
            variant="primary"
            onClick={toggleBlacklistAllModal}
            isCapitalized={false}
        />
    );

    const BackButtonComponent = (
        <SmallButton variant="secondary" onClick={handleNavigate}>
            {t.buttons.back}
        </SmallButton>
    );

    const BlockAllModalComponent = isBlockAllAvailable ? (
        <BlockAllModal
            isOpen={isBlacklistAllModalVisible}
            setOn={setBlacklistAllModalVisibility}
        />
    ) : null;

    const actionHeaderContentList = [
        {
            key: "blacklist-all-button-component",
            content: isBlockAllAvailable ? BlockAllButtonComponent : null,
        },
        {
            key: "back-button-component",
            content: BackButtonComponent,
        },
    ];

    return { BlockAllModalComponent, actionHeaderContentList };
}
