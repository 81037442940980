import * as Yup from "yup";

import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import { TranslationsTypes } from "../../../../hooks/useTranslation";
import { EditQueryType } from "../../../../types/queriesTypes";

const fieldsList: Array<keyof EditQueryType> = [
    "name",
    "date_range",
    "template",
    "period",
    "query_type",
    "start_time",
    "partners",
    "services",
    "processing",
];

const validationSchema = (t: TranslationsTypes, translate: (text: string) => string) => (
    Yup.object().shape({
        name: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`),
            ),
        template: Yup.string().required(t.validation.required),
        date_range: Yup.string().required(t.validation.required),
        query_type: Yup.string().required(t.validation.required),

        period: Yup.string()
            .when("query_type", ([query_type], schema) => (((
                query_type === "manual" || query_type === null
            ))
                ? schema.nullable()
                : schema.required(t.validation.required))),

        start_time: Yup.string()
            .when("query_type", ([query_type], schema) => (((
                query_type === "manual" || query_type === null
            ))
                ? schema.nullable()
                : schema.required(t.validation.required))),

        partners: Yup.array()
            .when(["services", "processing"], ([services, processing], schema) => ((
                services || processing
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),

        services: Yup.array()
            .when(["partners", "processing"], ([partners, processing], schema) => ((
                partners || processing
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),

        processing: Yup.string()
            .when(["partners", "services"], ([partners, services], schema) => ((
                partners || services
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),

        description: Yup.string().nullable()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    }, [["partners", "services"], ["partners", "processing"], ["services", "processing"]])
);

const getFieldStates = (formikValues: EditQueryType, queriesLoading: boolean) => {
    const {
        services, partners, processing, query_type,
    } = formikValues;

    return {
        isPartnersFieldRequired: !services?.length && !processing,
        isServicesFieldRequired: !partners?.length && !processing,
        isProcessingFieldRequired: !services?.length && !partners?.length,

        isPartnersFieldDisabled: queriesLoading || !!services?.length || !!processing,
        isServicesFieldDisabled: queriesLoading || !!partners?.length || !!processing,
        isProcessingFieldDisabled: queriesLoading || !!partners?.length || !!services?.length,
        isPeriodAndDateFieldDisabled: query_type === "manual" || query_type === null,
    };
};

export {
    fieldsList,
    validationSchema,
    getFieldStates,
};
