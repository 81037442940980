import { QueriesTypes } from "../../types/queriesTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: QueriesTypes,
}

export const getSelectedQuery = (queryId: string) => request<ResponseType>({
    method: "GET",
    uri: `queries/${queryId}/`,
});
