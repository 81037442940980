import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import { capitalizeFirstLetter, extractValues } from "../../../../../helpers";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import alertsStore from "../../../../../store/alerts/alerts/alertsStore";
import authStore from "../../../../../store/auth/authStore";
import { AlertProcessingTypes } from "../../../../../types/alertsTypes";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import {
    DetailsBodyWrapper,
    DetailsPageWrapper,
    DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import ProtectedAlertsListsTable from "./components/ProtectedAlertsListsTable";
import EditAlertModal from "./EditAlertModal";
import { useTabsActions } from "./hooks/useTabsActions";
import GlobalLoader from "../../../../ui/loader/GlobalLoader";

const displayAttributesMap = {
    service_id: "Service ID",
    partner_id: "Partner ID",
    customer_id: "Customer ID",
    email: "E-mail",
    phone: "Phone",
    ip: "IP",
    card_hash: "Card Hash",
    created: "Created at",
};

const AlertDetails = () => {
    const { t } = useTranslation();
    const { id: alertId } = useParams();
    const { userPermissions } = authStore;
    const { selectedItem } = alertsStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTitle = alertId ? `${t.alerts.text.partOfLink} №${alertId}` : "";
    const headerTitle = generateHeaderName(t.alerts.title, currentTitle);

    const isListsTableCheckboxVisible = userPermissions.includes(permissionsMap.lists.changeStatus);

    const preInputsParamsList = [
        { label: t.alerts.placeholders.name, value: selectedItem?.name, key: "alert-details-name" },
        { label: t.alerts.placeholders.id, value: selectedItem?.id, key: "alert-details-id" },
        {
            label: t.alerts.placeholders.displayAttributes,
            value: extractValues(displayAttributesMap, selectedItem?.display_attributes).join(", "),
            key: "alert-details-display-attributes",
        },
        {
            label: t.alerts.placeholders.type,
            value: `${selectedItem?.alert_type.toUpperCase()} alert`,
            key: "alert-details-type",
        },
        {
            label: t.alerts.placeholders.triggerText,
            value: selectedItem?.trigger_text,
            key: "alert-details-trigger-text",
        },
        {
            label: t.alerts.placeholders.tgGroup,
            value: selectedItem?.tg_group,
            is_hidden: selectedItem?.alert_type === "af",
            key: "alert-details-tg-group",
        },
        {
            label: t.alerts.placeholders.importance,
            value: capitalizeFirstLetter(selectedItem?.importance),
            key: "alert-details-importance",
        },
        { label: t.alerts.placeholders.createdAt, value: selectedItem?.created, key: "alert-details-created_at" },
        {
            label: t.alerts.placeholders.createdBy,
            value: selectedItem?.created_by_name,
            key: "alert-details-created_by",
        },
        {
            label: t.alerts.placeholders.partner,
            value: selectedItem?.partners.map((partner) => partner.name).join(", "),
            key: "alert-details-partners",
        },
        {
            label: t.alerts.placeholders.service,
            value: selectedItem?.services.map((service) => service.name).join(", "),
            key: "alert-details-services",
        },
        {
            label: t.alerts.placeholders.processing,
            value: (selectedItem?.processing as AlertProcessingTypes)?.name,
            key: "alert-details-processing",
        },
    ];

    const {
        ActionModalComponent,
        actionHeaderContentList,
        listsTableHeaderActionContentList,
    } = useTabsActions();

    useEffect(() => {
        if (alertId) alertsStore.fetchSelectedItem(alertId);

        return () => alertsStore.clearSelectedItem();
    }, [alertId]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.alerts.text.partOfLink} ${t.details.title}`}
                        permission={permissionsMap.alerts.change}
                        isStatusActive={selectedItem?.is_active}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsParamsList.map(({ is_hidden, ...input }) => (
                            is_hidden ? null : <PreInput {...input} />))}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.alerts.placeholders.message} value={selectedItem?.description} />

                </DetailsSectionWrapper>

                <ProtectedAlertsListsTable
                    permission={permissionsMap.lists.view}
                    actionsContent={listsTableHeaderActionContentList}
                    isCheckboxVisible={isListsTableCheckboxVisible}
                />

                <EditAlertModal
                    title={t.alerts.edit.title}
                    buttonText={t.buttons.save}
                    isOpen={isEditModalVisible}
                    setOn={toggleEditModal}
                />

                {ActionModalComponent}
            </DetailsPageWrapper>

            <GlobalLoader
                isLoading={alertsStore.isGettingItemDetailsInProgress}
                size="32"
                variant="tertiary"
            />
        </>

    );
};

export default observer(AlertDetails);
