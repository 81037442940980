import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import routes from "../../../../constants/routes";
import queriesStore from "../../../../store/queries/queries/queriesStore";
import { QueriesTypes } from "../../../../types/queriesTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";
import TableFieldsStatusBadge from "../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";

const columns: ColumnType<QueriesTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.name",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.query.href}/${dataItem.id}`}>
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.queryId", field: "id" },
    { header: "table.header.type", field: "query_type" },
    { header: "table.header.createdAt", field: "created" },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        width: "180px",
        isResizable: true,
    },
    {
        header: "table.header.partnerId",
        field: "partners",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => dataItem.partners.map((partner) => partner.id).join(", "),
    },
    {
        header: "table.header.serviceId",
        field: "services",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => dataItem.services.map((service) => service.id).join(", "),
    },
    {
        header: "table.header.processingName",
        field: "processing",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => dataItem.processing?.name,
    },
    {
        header: "table.header.isActive",
        field: "is_active",
        sortable: true,
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => <TableFieldsDetailsLink dataItem={dataItem} href={routes.query.href} />,
    },
];

const QueriesTable = () => {
    const tablePagination = {
        ...queriesStore.pagination,
        setPaginationPage: queriesStore.setPaginationPage,
        setPaginationSize: queriesStore.setPaginationSize,
    };

    useEffect(() => {
        queriesStore.fetchData();
    }, [queriesStore.pagination, queriesStore.filters, queriesStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            pagination={tablePagination}
            data={queriesStore.data}
            sortMode={queriesStore.ordering}
            loading={queriesStore.loading}
            checkedItems={queriesStore.checkedItems}
            onSort={queriesStore.toggleSort}
            onCheckboxChange={queriesStore.toggleItem}
            onMainCheckboxChange={queriesStore.toggleMainItem}
        />
    );
};

export default observer(QueriesTable);
