import { observer } from "mobx-react-lite";
import { memo } from "react";
import useTranslation from "../../../../../../hooks/useTranslation";
import authStore from "../../../../../../store/auth/authStore";
import { ActionModalType } from "../../../../../../types/actionModalTypes";
import MenuDropdown from "../../../../../ui/menuDropdown/MenuDropdown";
import { getListsDetailsMenuItems } from "./listsDetailsMenuHelpers";
import withPermission from "../../../../../../hocs/withPermissions";

interface ProtectedListsDetailsMenuProps {
    store: any;
    isDownloadButtonDisabled: boolean;
    isUploadButtonDisabled: boolean;
    toggleUploadModal: () => void;
    downloadFile: () => Promise<void>;
    toggleActionModal: (type?: ActionModalType) => void;
}

type ProtectedMenuProps = ProtectedListsDetailsMenuProps & {
    permissions: string | string[];
}

const WrappedComponent = observer(({
    store, isDownloadButtonDisabled, isUploadButtonDisabled, toggleUploadModal, downloadFile, toggleActionModal,
}: ProtectedListsDetailsMenuProps) => {
    const { translate } = useTranslation();
    const { userPermissions } = authStore;

    const menuDropdownItemsList = getListsDetailsMenuItems(
        store,
        isDownloadButtonDisabled,
        isUploadButtonDisabled,
        userPermissions,
        translate,
        toggleUploadModal,
        downloadFile,
        toggleActionModal,
    );

    return <MenuDropdown items={menuDropdownItemsList} />;
});

const ProtectedListDetailsMenu = ({ permissions, ...props }: ProtectedMenuProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permissions);
    return <ComponentWithPermission {...props} />;
};

export default memo(ProtectedListDetailsMenu);
