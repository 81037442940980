import { colors } from "../styles/variables";

const transactionsChartsLinesConfigList = [
    {
        key: "af-all",
        name: "AF",
        dataKey: "af_all",
        stroke: colors.textPrimary,
    },
    {
        key: "af-approve",
        name: "AF approved",
        dataKey: "af_approve",
        stroke: colors.successBackground,
    },
    {
        key: "af-decline",
        name: "AF decline",
        dataKey: "af_decline",
        stroke: colors.error,
    },
    {
        key: "core-all",
        name: "Core",
        dataKey: "core_all",
        stroke: colors.textPrimary,
    },
    {
        key: "core-success",
        name: "Core success",
        dataKey: "core_success",
        stroke: colors.successBackground,
    },
    {
        key: "core-decline",
        name: "Core decline",
        dataKey: "core_decline",
        stroke: colors.error,
    },
];

export {
    transactionsChartsLinesConfigList,
};
