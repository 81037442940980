import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { transactionsChartsLinesConfigList } from "../../../constants/chartsStatistics";
import useTranslation from "../../../hooks/useTranslation";
import filtersAlertsStore from "../../../store/filters/alerts/alertsFiltersStore";
import filtersLimitsStore from "../../../store/filters/limitsFilterStore";
import filtersPartnersStore from "../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../store/filters/servicesFilterStore";
import homeStore from "../../../store/home/homeStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import TabHeader from "../../tabHeader/TabHeader";
import { DetailsPageWrapper } from "../../ui/details/DetailsComponents";
import EmptyView from "../../ui/emptyView/EmptyView";
import Chart from "./components/Chart/Chart";
import ProgressInfo from "./components/ProgressInfo/ProgressInfo";
import { Box } from "./components/ProgressInfo/ProgressInfoComponents";
import { useTabsActions } from "./hooks/useTabsActions";

const PagesHome = () => {
    const { t } = useTranslation();

    const { FilterModalComponent, transactionsActionHeaderContentList } = useTabsActions();

    useEffect(() => {
        filtersPartnersStore.fetch();
        filtersServicesStore.fetch();
        filtersProcessingStore.fetch();
        filtersAlertsStore.fetch();
        filtersLimitsStore.fetch();
    }, []);

    return (
        <DetailsPageWrapper>
            <SecondHeader
                isMargin={false}
                title={t.menu.links.home}
                actionsContent={transactionsActionHeaderContentList}
            />

            {homeStore.data.length ? (
                <>
                    <TabHeader title={t.transactions.title} />

                    <Box>
                        <ProgressInfo type="decision" statistic={homeStore.afStatisticInfo} />
                        <ProgressInfo type="final" statistic={homeStore.coreStatistic} />
                    </Box>

                    <Chart
                        data={homeStore.data}
                        lines={transactionsChartsLinesConfigList}
                    />
                </>
            ) : (
                <EmptyView
                    title={t.home.emptyView.title}
                    text={t.home.emptyView.text}
                />
            )}

            {FilterModalComponent}
        </DetailsPageWrapper>
    );
};

export default observer(PagesHome);
