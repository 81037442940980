/* eslint-disable camelcase */
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Routes from "../../../../constants/routes";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import { removeEmptyFields } from "../../../../helpers/removeEmptyFields";
import useTranslation from "../../../../hooks/useTranslation";
import filtersProcessingStore from "../../../../store/filters/processingFilterStore";
import rulesPackStore from "../../../../store/rulesPack/rulesPackStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import accountStore from "../../../../store/user/account/accountStore";
import { NewRuleTypes } from "../../../../types/rulesPacksTypes";
import SecondHeader from "../../../secondHeader/SecondHeader";
import Button from "../../../ui/button/Button";
import SmallButton from "../../../ui/button/SmallButton";
import Switch from "../../../ui/switch/Switch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";
import {
    Box, ButtonBox, Container, FormWrapper, Row, Title,
} from "./NewRulesPackComponents";
import { usePreventRedirect } from "../../../../hooks/usePreventRedirect";

const fieldsList = [
    "name", "description", "is_active", "processing",
];

const PagesNewRulesPack = () => {
    const { t, translate } = useTranslation();
    const navigation = useNavigate();

    const handleNavigateToPrevPage = () => {
        navigation(Routes.rulesPacks.href);
    };

    const userProcessingId = accountStore.userProfile?.processing || null;

    const initialValues: NewRuleTypes = {
        name: "",
        processing: userProcessingId,
        description: "",
        is_active: true,
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        processing: Yup.string().required(t.validation.required),
    });

    const formik = useFormik<NewRuleTypes>({
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            const filteredData = removeEmptyFields(fields, "is_active");
            await rulesPackStore.postData(filteredData, fieldsList);

            if (rulesPackStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                rulesPackStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.rulesPacks.snackbar.addSuccess });
            }
        },
    });

    const togglePartnerStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const actionHeaderContentList = [
        {
            key: "cancel_button",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, rulesPackStore.isPostRequestSuccess);

    useEffect(() => {
        filtersProcessingStore.fetch();

        return () => {
            rulesPackStore.resetPostErrors();
        };
    }, []);

    return (
        <>
            <SecondHeader title={t.rulesPacks.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.rulesPacks.text.title}</Title>
                    <Row>
                        <Title>{t.status.title}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch checked={formik.values.is_active} onChange={togglePartnerStatus} />
                    </Row>
                </Row>

                <FormWrapper onSubmit={handleSubmit}>
                    <Box>
                        <FloatInput
                            formik={formik}
                            disabled={rulesPackStore.loading}
                            name="name"
                            isRequired
                            placeholder={t.rulesPacks.placeholder.name}
                            serverErrors={rulesPackStore.postErrors}
                            resetServerError={rulesPackStore.resetPostError}
                        />
                    </Box>
                    <Box>
                        <FloatSelect
                            formik={formik}
                            placeholder={t.placeholder.processing}
                            name="processing"
                            isRequired
                            disabled={!!userProcessingId || rulesPackStore.loading}
                            options={filtersProcessingStore.options}
                            serverErrors={rulesPackStore.postErrors}
                            resetServerError={rulesPackStore.resetPostError}
                        />
                    </Box>
                    <Box>
                        <FloatTextArea
                            formik={formik}
                            disabled={rulesPackStore.loading}
                            name="description"
                            placeholder={t.placeholder.description}
                            serverErrors={rulesPackStore.postErrors}
                            resetServerError={rulesPackStore.resetPostError}
                            autoSize
                            maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                        />
                    </Box>
                    <ButtonBox>
                        <Button
                            variant="primary"
                            type="submit"
                            isLoading={rulesPackStore.loading}
                            disabled={rulesPackStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    </ButtonBox>
                </FormWrapper>
            </Container>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(PagesNewRulesPack);
