import React from "react";

import { CustomPlaceholder, DescriptionInputWrapper, StyledTextarea } from "./InputStyles";
import { BoxProps } from "./testInputTypes";
import { CopyButton } from "../../authorizationLayout/TFA/TFALink/TFALinkComponents";
import snackbarStore from "../../../store/snackbar/snackbarStore";
import useTranslation from "../../../hooks/useTranslation";
import { ReactComponent as CopyIcon } from "../../../resources/icons/copyIcon.svg";

const TextareaLabelInRow = ({ label, value, isCopyIconShown = false }: BoxProps) => {
    const { t } = useTranslation();

    const handleCopyAction = () => {
        let textToCopy = "";
        if (Array.isArray(value)) {
            textToCopy = value.join(", ");
        } else if (typeof value === "number" || typeof value === "string") {
            textToCopy = value.toString();
        }

        navigator.clipboard.writeText(textToCopy).then(() => {
            snackbarStore.add({ variant: "success", text: t.snackbar.copySuccess });
        });
    };

    return (
        <DescriptionInputWrapper>
            <CustomPlaceholder>
                <div>
                    {label}
                </div>
                {isCopyIconShown && (
                    <CopyButton
                        $size="16"
                        onClick={handleCopyAction}
                    >
                        <CopyIcon width="16" height="16" />
                    </CopyButton>
                )}
            </CustomPlaceholder>
            <StyledTextarea
                rows={4}
                readOnly
                value={value || ""}
                autoSize={{ minRows: 1, maxRows: 16 }}
            />
        </DescriptionInputWrapper>
    );
};

export default TextareaLabelInRow;
