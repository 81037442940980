import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FormEvent, useEffect } from "react";

import {
    getTranslatedHandbook,
    queriesQueryPeriodHandbook,
    queriesRangeOptionsHandbook,
    queriesTypeOptionsHandbook,
} from "../../../../../constants/handbooks";
import { FieldMode } from "../../../../../constants/selectFields";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { usePreventRedirect } from "../../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../../hooks/useTranslation";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import filtersTemplateStore from "../../../../../store/filters/templateFiltersStore";
import queriesStore from "../../../../../store/queries/queries/queriesStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { NewQueryType } from "../../../../../types/queriesTypes";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import Button from "../../../../ui/button/Button";
import SmallButton from "../../../../ui/button/SmallButton";
import DatePicker from "../../../../ui/datepicker/DatePicker";
import {
    Container,
    DescriptionFieldWrapper,
    Row,
    SubmitButtonWrapper,
    ThreeColumnForm,
    Title,
} from "../../../../ui/new/NewComponents";
import Switch from "../../../../ui/switch/Switch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import {
    fieldsList,
    getFieldStates,
    initialValues,
    validationSchema,
} from "../../helpers/newQueryFormikHelpers";
import { removeEmptyFields } from "../../../../../helpers/removeEmptyFields";

const NewQuery = () => {
    const { t, translate } = useTranslation();

    const { navigateBackward } = useNavigation();
    const handleNavigateToPrevPage = () => navigateBackward();

    const fieldsOptionsMap = {
        range: getTranslatedHandbook(translate, queriesRangeOptionsHandbook),
        query: getTranslatedHandbook(translate, queriesTypeOptionsHandbook),
        period: getTranslatedHandbook(translate, queriesQueryPeriodHandbook),
    };

    const formik = useFormik<NewQueryType>({
        initialValues,
        validationSchema: validationSchema(t, translate),
        enableReinitialize: true,

        onSubmit: async (fields) => {
            const filteredData = removeEmptyFields(fields, "is_active");

            await queriesStore.postData(filteredData as NewQueryType, fieldsList);

            if (queriesStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                queriesStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.queries.snackbar.addSuccess });
            } else if (queriesStore.postErrors?.common) {
                snackbarStore.add({ variant: "error", text: t.snackbar.addError });
            }
        },
    });

    const fieldStates = getFieldStates(formik.values, queriesStore.loading);

    const actionHeaderContentList = [
        {
            key: "cancel-button-component",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const togglQueryStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleChangeType = (value: unknown) => {
        if (value === "manual" || !value) {
            formik.setFieldValue("period", "", false);
            formik.setFieldValue("start_time", "", false);
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, queriesStore.isPostRequestSuccess);

    useEffect(() => {
        filtersPartnersStore.fetch();
        filtersServicesStore.fetch();
        filtersTemplateStore.fetch();
        filtersProcessingStore.fetch();

        return () => queriesStore.resetPostErrors();
    }, []);

    return (
        <>
            <SecondHeader title={t.queries.text.headerTittle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.queries.text.title}</Title>
                    <Row>
                        <Title>{t.queries.text.status}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            disabled={queriesStore.loading}
                            checked={formik.values.is_active}
                            onChange={togglQueryStatus}
                        />
                    </Row>
                </Row>

                <ThreeColumnForm onSubmit={handleSubmit}>
                    <FloatInput
                        formik={formik}
                        disabled={queriesStore.loading}
                        name="name"
                        isRequired
                        placeholder={t.queries.placeholders.name}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={queriesStore.loading}
                        name="template"
                        options={filtersTemplateStore.options}
                        isRequired
                        placeholder={t.queries.placeholders.template}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="date_range"
                        isRequired
                        options={fieldsOptionsMap.range}
                        placeholder={t.queries.placeholders.range}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="partners"
                        mode={FieldMode.Multiple}
                        isRequired={fieldStates.isPartnersFieldRequired}
                        disabled={fieldStates.isPartnersFieldDisabled}
                        options={filtersPartnersStore.options}
                        placeholder={t.queries.placeholders.partner}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="services"
                        mode={FieldMode.Multiple}
                        isRequired={fieldStates.isServicesFieldRequired}
                        disabled={fieldStates.isServicesFieldDisabled}
                        options={filtersServicesStore.options}
                        placeholder={t.queries.placeholders.service}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="processing"
                        isRequired={fieldStates.isProcessingFieldRequired}
                        disabled={fieldStates.isProcessingFieldDisabled}
                        options={filtersProcessingStore.options}
                        placeholder={t.queries.placeholders.processing}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={queriesStore.loading}
                        name="query_type"
                        options={fieldsOptionsMap.query}
                        isRequired
                        onChange={handleChangeType}
                        placeholder={t.queries.placeholders.type}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="period"
                        options={fieldsOptionsMap.period}
                        disabled={fieldStates.isPeriodAndDateFieldDisabled}
                        isRequired={!fieldStates.isPeriodAndDateFieldDisabled}
                        placeholder={t.queries.placeholders.queryPeriod}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <DatePicker
                        formik={formik}
                        maxDate={formik.values.start_time}
                        name="start_time"
                        disabled={fieldStates.isPeriodAndDateFieldDisabled}
                        placeholder={t.queries.placeholders.queryStartTime}
                        serverErrors={queriesStore.postErrors}
                        resetServerError={queriesStore.resetPostError}
                    />
                    <DescriptionFieldWrapper>
                        <FloatInput
                            formik={formik}
                            disabled={queriesStore.loading}
                            name="description"
                            placeholder={t.queries.placeholders.description}
                            serverErrors={queriesStore.postErrors}
                            resetServerError={queriesStore.resetPostError}
                        />
                    </DescriptionFieldWrapper>

                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={queriesStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    </SubmitButtonWrapper>
                </ThreeColumnForm>
            </Container>

            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewQuery);
