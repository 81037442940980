import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { permissionsMap } from "../../../constants/permissions";
import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useTranslation from "../../../hooks/useTranslation";
import authStore from "../../../store/auth/authStore";
import filtersAlertsStore from "../../../store/filters/alerts/alertsFiltersStore";
import createdByFilterStore from "../../../store/filters/createdByFilterStore";
import filtersListsStore from "../../../store/filters/lists/listsFiltersStore";
import rulesPacksFiltersStore from "../../../store/filters/rulesPacksFiltersStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import ProtectedListsTable from "./components/ProtectedListsTable";
import { useTabsActions } from "./hooks/useTabsActions";

const PagesLists = () => {
    const { t } = useTranslation();
    const { userPermissions } = authStore;
    const headerTitle = generateHeaderName(t.lists.title);

    const isListsTableCheckboxVisible = userPermissions.includes(permissionsMap.lists.changeStatus);

    const {
        CloneListModalComponent, FilterModalComponent, ActionModalComponent, actionHeaderContentList,
    } = useTabsActions();

    useEffect(() => {
        filtersListsStore.fetch();
        filtersAlertsStore.fetch();
        rulesPacksFiltersStore.fetch();
        createdByFilterStore.fetch();
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <ProtectedListsTable
                permission={permissionsMap.lists.view}
                isCheckboxVisible={isListsTableCheckboxVisible}
            />
            {CloneListModalComponent}
            {FilterModalComponent}
            {ActionModalComponent}
        </>
    );
};

export default observer(PagesLists);
