import request from "../request";

export interface GetQueryStatusResponseTypes {
    message: string;
    response: {
        status: "SUCCESS" | "PENDING" | "ERROR";
        message: {
            result_id: number;
        }
    };
}

export const getQueryStatus = (taskId: string) => request<GetQueryStatusResponseTypes>({
    method: "GET",
    uri: `queries/status/${taskId}/`,
});
