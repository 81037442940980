import { ItemType } from "antd/es/menu/hooks/useItems";

import { permissionsMap } from "../../../../../../constants/permissions";
import { ReactComponent as CloseCircleIcon } from "../../../../../../resources/icons/closeCircleIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../../../../resources/icons/menu/download.svg";
import { ReactComponent as UploadIcon } from "../../../../../../resources/icons/menu/upload.svg";
import { ReactComponent as TickCircleIcon } from "../../../../../../resources/icons/tickCircleIcon.svg";
import { colors } from "../../../../../../styles/variables";
import { ActionModalType } from "../../../../../../types/actionModalTypes";
import MenuDropdownItem from "../../../../../ui/menuDropdown/MenuDropdownItem";

export const getListsDetailsMenuItems = (
    store: any,
    isDownloadButtonDisabled: boolean,
    isUploadButtonDisabled: boolean,
    userPermissions: string[],
    translate: (text: string) => string,
    toggleUploadModal: () => void,
    downloadFile: () => Promise<void>,
    toggleActionModal: (type: ActionModalType) => void,
): ItemType[] => {
    const itemsPropsList = [
        {
            key: "upload-item",
            disabled: isUploadButtonDisabled,
            permission: permissionsMap.elements.add,
            label: <MenuDropdownItem
                isLoading={store.isFileUploading}
                icon={<UploadIcon width="16px" height="16px" fill={colors.buttonPrimary} />}
                title={translate("lists.buttons.uploadFile")}
            />,
            onClick: toggleUploadModal,
        },
        {
            key: "download-item",
            disabled: isDownloadButtonDisabled,
            permission: permissionsMap.elements.add,
            label: <MenuDropdownItem
                isLoading={store.isFileLoading}
                icon={<DownloadIcon width="16px" height="16px" fill={colors.buttonPrimary} />}
                title={translate("lists.buttons.downloadFile")}
            />,
            onClick: downloadFile,
        },
        {
            key: "activate-item",
            label: <MenuDropdownItem
                icon={<TickCircleIcon width="16px" height="16px" />}
                title={translate("buttons.activate")}
            />,
            disabled: !store.hasDisabledCheckedItems,
            permission: permissionsMap.elements.changeStatus,
            onClick: () => toggleActionModal("activate"),
        },
        {
            key: "deactivate-item",
            label: <MenuDropdownItem
                icon={<CloseCircleIcon fill={colors.error} width="16px" height="16px" />}
                title={translate("buttons.deactivate")}
            />,
            disabled: !store.hasActiveCheckedItems,
            permission: permissionsMap.elements.changeStatus,
            onClick: () => toggleActionModal("deactivate"),
        },
    ];

    return itemsPropsList.filter(({ permission }) => userPermissions.includes(permission));
};
