import { EditQueryType, QueriesTypes } from "../../types/queriesTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: QueriesTypes,
}

const patchAlert = (data: EditQueryType, queryId: string) => request<ResponseType>({
    method: "PATCH",
    uri: `queries/${queryId}/`,
    data,
});

export default patchAlert;
