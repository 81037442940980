import { useState } from "react";

import Routes from "../../../../constants/routes";
import { objectToValuesList } from "../../../../helpers";
import { toggleActionModalState } from "../../../../helpers/modalHelper";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import queriesStore from "../../../../store/queries/queries/queriesStore";
import resultsStore from "../../../../store/queries/results/resultsStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { ActionModalState, ActionModalType } from "../../../../types/actionModalTypes";
import { FetchStatusesType } from "../../../../types/fetchStatuses";
import { PermissionKeys } from "../../../../types/permissionsTypes";
import { TabsType } from "../../../../types/tabs";
import ActionModal from "../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon, PlusIcon } from "../../../ui/button/ButtonComponents";
import SmallButton from "../../../ui/button/SmallButton";
import Link from "../../../ui/link/Link";
import QueryMenu from "../components/QueryMenu";
import QueriesFilters from "../queries/QueriesFilters";
import ResultsFilters from "../results/ResultsFilters";

type TabTitlesKeys = Extract<PermissionKeys, "queries" | "results">;

export function useTabsActions(currentTabTitle: PermissionKeys, currentTab?: TabsType) {
    const { t, concatenate } = useTranslation();

    const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
    const toggleFilterModal = () => setFilterModalVisibility((prev) => !prev);

    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const queriesCustomText = actionModalState.type === "deactivate"
        ? t.queries.text.deactivate : t.queries.text.activate;

    const tabsPropertiesMap = {
        queries: {
            store: queriesStore,
            entityName: t.queries.text.partOfLink,
            actionModalBodyText: queriesCustomText,
            iaMenuDropdownShown: true,
            isAddButtonShown: true,
            filters: <QueriesFilters closeModal={toggleFilterModal} />,
        },
        results: {
            store: resultsStore,
            entityName: null,
            actionModalBodyText: null,
            iaMenuDropdownShown: false,
            isAddButtonShown: false,
            filters: <ResultsFilters closeModal={toggleFilterModal} />,
        },
    };

    const {
        store, entityName, actionModalBodyText, isAddButtonShown, iaMenuDropdownShown, filters,
    } = tabsPropertiesMap[currentTabTitle as TabTitlesKeys];

    const currentAddButtonTitle = currentTabTitle?.slice(0, currentTabTitle.length - 1);

    const storesFilters = store.filters ?? {};

    const filtersCount = objectToValuesList(storesFilters).length;

    const handleRequestResult = async (
        result: FetchStatusesType | undefined,
        successMessage: string,
        errorMessage: string,
    ) => {
        if (result === FetchStatusesType.success) {
            await store.fetch();
            snackbarStore.add({ variant: "success", text: successMessage });
        } else {
            snackbarStore.add({ variant: "error", text: errorMessage });
        }
    };

    const AddButtonComponent = (
        <Link to={`${Routes.queries.href}/${currentTabTitle}/new`} state={{ tab: currentTab }}>
            <SmallButton
                variant="tertiary"
                iconLeft={<PlusIcon />}
            >
                {currentAddButtonTitle}
            </SmallButton>
        </Link>
    );

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
            onClick={toggleFilterModal}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const MenuComponent = (
        <QueryMenu
            store={store}
            toggleActionModal={toggleActionModal}
        />
    );

    const activateItems = async () => {
        const result = await store.activateSelectedItems();
        toggleActionModal();
        const snackbarSuccessText = concatenate(t.snackbar.activate.success, { itemsIdsList: store.activatedItems });
        handleRequestResult(result, snackbarSuccessText, t.snackbar.activate.error);
    };

    const deactivateItems = async () => {
        const result = await store.deactivateSelectedItems();
        toggleActionModal();
        const snackbarSuccessText = concatenate(
            t.snackbar.deactivate.success,
            { itemsIdsList: store.deactivatedItems },
        );
        handleRequestResult(result, snackbarSuccessText, t.snackbar.deactivate.error);
    };

    const ActionModalComponent = (
        <ActionModal
            handleDeactivate={deactivateItems}
            handleActivate={activateItems}
            entityName={entityName}
            customTitleText={actionModalBodyText}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const actionHeaderContentList = [
        { key: "add-button-component", content: AddButtonComponent, isDisplayed: isAddButtonShown },
        { key: "filters-button-component", content: FiltersButtonComponent },
        { key: "menu-dropdown-component", content: MenuComponent, isDisplayed: iaMenuDropdownShown },
    ];

    const FilterModalComponent = (
        <FiltersModal
            isOpen={isFilterModalVisible}
            setOn={toggleFilterModal}
        >
            {filters}
        </FiltersModal>
    );

    return { FilterModalComponent, ActionModalComponent, actionHeaderContentList };
}
