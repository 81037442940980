import styled from "styled-components";
import { DatePicker as AntDDatePicker, Input } from "antd";
import { StyledTextInputFiled } from "../textInput/InputStyles";
import { colors } from "../../../styles/variables";

export const DatePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const DatePickerComponent = styled(StyledTextInputFiled).attrs({
    as: AntDDatePicker,
})<{ $isDisabled: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 54px;
    gap: 8px;

    ::placeholder {
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }

    border: ${({ $isDisabled }) => $isDisabled && "none"};
    background-color: ${({ $isDisabled }) => $isDisabled && "#F2F5F9"} !important;

    &:hover {
      border: ${({ $isDisabled }) => $isDisabled && "none"};
    }

    .ant-picker-input input {
        color: ${colors.activeText};
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 105%;
    }

    &:focus-within {
        outline: none !important;
        border-color: ${colors.buttonPrimary} !important;
        box-shadow: none !important;
    }
`;

export const PreDatePickerWrapper = styled.div<{ $isError?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => (props.$isError ? colors.error : colors.inputDefaultBorder)};
  border-radius: 12px;
  gap: 4px;
  padding: 20px;
  box-sizing: border-box;
  max-height: 56px;

  .ant-input {
    max-height: 56px;
    color: ${(props) => (props.$isError ? colors.error : colors.activeText)};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  .ant-picker {
    flex-grow: 1;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    border: 0;

    color: ${(props) => (props.$isError ? colors.error : colors.activeText)};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;

    &:focus-within {
      outline: none !important;
      border-color: ${colors.buttonPrimary} !important;
      box-shadow: none !important;
    }

  }
  .ant-picker-input input {
    color: ${(props) => (props.$isError ? colors.error : colors.activeText)};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border: 0;
  }

  .ant-picker input::placeholder {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    color: ${(props) => (props.$isError ? colors.error : colors.textDefault)};
  }

`;

export const PreDatePickerStyledInput = styled(Input).attrs({
    as: Input,
})`
  border: none;
  outline: none;
  box-shadow: none;
  width: 35px;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  border-radius: 2px 0 0 2px;
`;
