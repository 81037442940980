import dayjs from "dayjs";

import { PeriodicStatisticTypes, PeriodStep } from "../types/statisticTypes";

const formatsMap: Record<PeriodStep, string> = {
    five_min: "HH:mm",
    hour: "HH:mm",
    six_hours: "MMM D",
    day: "MMM D",
    two_days: "MMM D",
    week: "MMM D",
};

const formatDataDate = (data: PeriodicStatisticTypes[], step: PeriodStep) => (
    data.map((item) => ({ ...item, date: dayjs(item.date).format(formatsMap[step]) }))
);

export {
    formatDataDate,
};
