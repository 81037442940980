import { View, Title, Text } from "./EmptyViewComponents";

interface EmptyViewProps {
    title: string;
    text: string;
}

const EmptyView = ({ title, text }: EmptyViewProps) => (
    <View>
        <Title>{title}</Title>
        <Text>{text}</Text>
    </View>
);

export default EmptyView;
