/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { AxiosResponse } from "axios";

import { getQueryStatus, GetQueryStatusResponseTypes } from "./getQueryStatus";

export async function generateQueryId(
    task_id: string,
    maxRetries = 5_000,
    retryDelay = 2_000,
): Promise<number | undefined> {
    let attemptsCount = 0;

    while (attemptsCount < maxRetries) {
        try {
            const response: AxiosResponse<GetQueryStatusResponseTypes, any> = await getQueryStatus(task_id);

            if (response.data.response.status === "SUCCESS") {
                return response.data.response.message.result_id;
            }
        } catch (error) {
            throw new Error(`Failed to generate the file after ${maxRetries} attempts`);
        }

        attemptsCount++;
        if (attemptsCount < maxRetries) {
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
    }
}
