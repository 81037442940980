import styled from "styled-components";

import { colors } from "../../../../../styles/variables";

export const WarningText = styled.span`
    color: ${colors.activeText};

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
`;
