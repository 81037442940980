import { NewQueryType, QueriesTypes } from "../../types/queriesTypes";
import request from "../request";

interface ResponseType {
    message: string;
    response: QueriesTypes;
}

export const postQueries = (data: NewQueryType) => request<ResponseType>({
    method: "POST",
    uri: "queries/",
    data,
});
