export const textAndIconColor = (color: string) => `
    color: ${color};
    * {
        color: ${color};
    }
    path {
        &[fill] {
            fill: ${color};
        }
        &[stroke] {
            stroke: ${color};
        }
    }
`;

export const colors = {
    inputDefaultBorder: "#D4D7E3",
    inputHoverBackground: "#F7FBFF",
    inputHoverBorder: "#CACDD9",
    activeText: "#242424",
    error: "#FF3B30",
    warning: "#FF9500",
    buttonErrorHover: "#D30D02",
    buttonDefaultBackground: "#E0E6FF",
    buttonSecondaryHover: "#B2C1FF",
    buttonPrimary: "#2D55FB",
    buttonPrimaryHover: "#1E46EC",
    buttonDisabledBorder: "#E1E3EA",
    buttonDisabledBackground: "#E1E3EA",
    buttonDisabledBackgroundSecondary: "#F8F8F8",
    switchDefaultBackground: "#E1E3EA",
    tableRowSelectedBackground: "#EFF2F6",
    tableRowZebraBackground: "#F2F5F9",
    selectDisabledBackground: "#F2F5F9",
    sortIcon: "#D4D7E3",
    sortIconActive: "#2D55FB",
    textDefault: "#BEBEBF",
    textPrimary: "#2D55FB",
    linkPrimary: "#4870EE",
    textDefaultHover: "#BEBEBF",
    textPrimaryHover: "#1E46EC",
    textDisabled: "#999999",
    tick: "#666",
    white: "#FFFFFF",
    black: "#000000",
    successBackground: "#34C759",
    inactive: "#D4D7E3",
    //  TODO: replace this variable with suitable name
    loginPageBackground: "#F8F8F8",
    mainLayoutBackground: "#F8F8F8",
    emptyTableBackground: "#F8F8F8",
    ellipseShadow: "rgba(45, 85, 251, 0.50)",
    welcomeText: "rgba(45, 85, 251, 0.44)",
    modalOverlayBackground: "rgba(36, 36, 36, 0.40)",
};
