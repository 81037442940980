import {
    computed,
    flow,
    makeObservable,
    observable,
} from "mobx";

import getStatistic from "../../api/home/getStatistic";
import { getCommonError } from "../../helpers/apiHelper";
import { formatDataDate } from "../../helpers/statisticsHelper";
import { FetchStatusesType } from "../../types/fetchStatuses";
import {
    StatisticTypes,
    AfStatisticTypes,
    CoreStatisticTypes,
    PeriodicStatisticTypes,
    StatisticFiltersTypes,
} from "../../types/statisticTypes";
import dataStore from "../common/dataStore";

interface ResponseTypes {
    data: StatisticTypes;
}

class HomeStore extends dataStore<PeriodicStatisticTypes, StatisticFiltersTypes, null> {
    @observable private _coreStatistic: CoreStatisticTypes | null = null;

    @observable private _afStatistic: AfStatisticTypes | null = null;

    constructor() {
        super("HomeStore");
        makeObservable(this);
    }

    @computed
    public get coreStatistic() {
        return this._coreStatistic;
    }

    @computed
    public get afStatisticInfo() {
        return this._afStatistic;
    }

    public fetchData = flow(function* fetchData(this: HomeStore) {
        try {
            if (this._filters) {
                this.fetchState = FetchStatusesType.pending;

                const data = this.parseFilters(this._filters);

                const response: ResponseTypes = yield getStatistic(data);

                this._data = formatDataDate(response.data.response.periodic, response.data.response.step);
                this._coreStatistic = response.data.response.core;
                this._afStatistic = response.data.response.af;

                this.fetchState = FetchStatusesType.success;
            }
        } catch (error) {
            this.fetchState = FetchStatusesType.failed;
            this._fetchErrors = getCommonError(error);
        }
    });
}

const homeStore = new HomeStore();

export default homeStore;
