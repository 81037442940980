import { colors } from "../../../../../styles/variables";

const lineChartStyle = {
    top: 0,
    right: 25,
    left: 0,
    bottom: 0,
};

const tooltipViewStyle = {
    borderWidth: 1,
    borderColor: colors.inputDefaultBorder,
    borderRadius: 6,
    padding: 10,
};

const tooltipLabelStyle = {
    color: colors.activeText,
    fontFamily: "Roboto",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "105%",
};

const tooltipItemStyle = {
    fontFamily: "Roboto",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "105%",
};

export {
    lineChartStyle,
    tooltipViewStyle,
    tooltipLabelStyle,
    tooltipItemStyle,
};
