import FiltersDataStore from "../common/filtersStore";
import { TemplateType } from "../../types/commonFiltersType";
import { getTemplates } from "../../api/queries/filters/getTemplates";

class FiltersTemplateStore extends FiltersDataStore<TemplateType> {
    protected fetchListMethod = getTemplates;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const filtersTemplateStore = new FiltersTemplateStore();

export default filtersTemplateStore;
