import styled from "styled-components";

import { colors } from "../../../../../styles/variables";

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

const Text = styled.span`
    color: ${colors.activeText};
    font-family: Roboto;
    font-style: normal;
    line-height: 105%;
`;

const ProgressInfoContainer = styled.div`
    display: flex;
    flex: 1;
    border-radius: 12px;
    background: ${colors.white};
    padding: 20px;
    flex-direction: column;
    gap: 20px;
`;

const TitleSectionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const Title = styled(Text)`
    font-size: 20px;
    font-weight: 600;
`;

const TitleSectionMetricContainer = styled.span``;

const TitleSectionMetric = styled(Text)<{ $isSecondary?: boolean }>`
    color: ${(props) => (props.$isSecondary ? colors.textDisabled : colors.activeText)};
    font-weight: ${(props) => (props.$isSecondary ? 400 : 500)};;
    font-size: 16px;
`;

const MetricIndicatorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const IndicatorContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

const Indicator = styled.div<{ $type: "success" | "decline" }>`
    width: 10px;
    height: 10px;
    border-radius: 40px;
    background-color: ${(props) => (props.$type === "success" ? colors.successBackground : colors.error)};
`;

const MetricIndicatorTitle = styled(Text)`
    font-size: 14px;
    font-weight: 600;
`;

const MetricTextContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
`;

const MetricText = styled(Text)`
    text-align: right;
    font-size: 14px;
    font-weight: 400;
`;

export {
    Box,
    ProgressInfoContainer,
    TitleSectionContainer,
    Title,
    TitleSectionMetricContainer,
    TitleSectionMetric,

    MetricIndicatorContainer,
    IndicatorContainer,
    Indicator,
    MetricIndicatorTitle,
    MetricTextContainer,
    MetricText,
};
