/* eslint-disable camelcase */
import { Progress } from "antd";

import useTranslation from "../../../../../hooks/useTranslation";
import { colors } from "../../../../../styles/variables";
import { AfStatisticTypes, CoreStatisticTypes } from "../../../../../types/statisticTypes";
import MetricIndicator from "./MetricIndicator";
import {
    Title,
    ProgressInfoContainer,
    TitleSectionContainer,
    TitleSectionMetric,
    TitleSectionMetricContainer,
} from "./ProgressInfoComponents";

interface ProgressInfoProps {
    statistic: CoreStatisticTypes | AfStatisticTypes | null;
    type: "decision" | "final";
}

const ProgressInfo = ({ statistic, type }: ProgressInfoProps) => {
    const { t } = useTranslation();

    const progressTitleMap = {
        decision: {
            title: t.home.text.decision,
            successLabel: t.home.progressInfo.afApprove,
            declineLabel: t.home.progressInfo.afDecline,
            success: "approve_all",
            successPercent: "approve_all_percent",
            decline: "decline_all",
            declinePercent: "decline_all_percent",
        },
        final: {
            title: t.home.text.final,
            successLabel: t.home.progressInfo.coreSuccess,
            declineLabel: t.home.progressInfo.coreDecline,
            success: "success_all",
            successPercent: "success_all_percent",
            decline: "decline_all",
            declinePercent: "decline_all_percent",
        },
    };

    const configMap = progressTitleMap[type];

    const {
        all,
        [configMap.success]: successValue,
        [configMap.successPercent]: successPercent,
        [configMap.decline]: declineValue,
        [configMap.declinePercent]: declinePercent,
    } = statistic as any;

    return (
        <ProgressInfoContainer>
            <TitleSectionContainer>
                <Title>{configMap.title}</Title>
                <TitleSectionMetricContainer>
                    <TitleSectionMetric $isSecondary>
                        {all.toLocaleString("ru-RU")}
                    </TitleSectionMetric>
                </TitleSectionMetricContainer>
            </TitleSectionContainer>

            <Progress
                percent={successPercent}
                strokeColor={colors.successBackground}
                trailColor={colors.error}
                showInfo={false}
                strokeWidth={10}
            />

            <MetricIndicator
                status="success"
                statusTitle={configMap.successLabel}
                value={successValue}
                percentValue={successPercent}
            />
            <MetricIndicator
                status="decline"
                statusTitle={configMap.declineLabel}
                value={declineValue}
                percentValue={declinePercent}
            />
        </ProgressInfoContainer>
    );
};

export default ProgressInfo;
