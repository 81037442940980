import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Tabs } from "../../../constants/tabs";
import useTabs from "../../../hooks/useTabs";
import useTranslation from "../../../hooks/useTranslation";
import filtersPartnersStore from "../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import filtersQueriesStore from "../../../store/filters/queries/queriesFiltersStore";
import filtersResultsStore from "../../../store/filters/queries/resultsFiltersStore";
import filtersServicesStore from "../../../store/filters/servicesFilterStore";
import queriesStore from "../../../store/queries/queries/queriesStore";
import resultsStore from "../../../store/queries/results/resultsStore";
import { LocationState } from "../../../types/locationTypes";
import SecondHeader from "../../secondHeader/SecondHeader";
import { useTabsActions } from "./hooks/useTabsActions";
import QueriesTable from "./queries/QueriesTable";
import ResultsTable from "./results/ResultsTable";

const PagesQueries = () => {
    const { t } = useTranslation();
    const state: LocationState = useLocation()?.state;
    const defaultActiveTab = state?.tab;

    const queriesCount = queriesStore.pagination.count;
    const resultsCount = resultsStore.pagination.count;

    const headerTitle = t.queries.headerTitles;

    const tabsPropsList = [
        {
            title: t.queries.title,
            name: Tabs.QUERIES,
            itemsCount: queriesCount,
            content: <QueriesTable />,
        },
        {
            title: t.results.title,
            name: Tabs.RESULTS,
            itemsCount: resultsCount,
            content: <ResultsTable />,
        },
    ];

    const {
        TabsLayout, activeTab, currentTab, currentTabTitle,
    } = useTabs(tabsPropsList, defaultActiveTab);

    const {
        FilterModalComponent,
        ActionModalComponent,
        actionHeaderContentList,
    } = useTabsActions(currentTabTitle, currentTab);

    useEffect(() => {
        queriesStore.fetchData();
        resultsStore.fetchData();

        filtersResultsStore.fetch();
        filtersQueriesStore.fetch();
        filtersPartnersStore.fetch();
        filtersServicesStore.fetch();
        filtersProcessingStore.fetch();
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} tabs={TabsLayout} actionsContent={actionHeaderContentList} />

            {activeTab}

            {FilterModalComponent}

            {ActionModalComponent}
        </>
    );
};

export default observer(PagesQueries);
