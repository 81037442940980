import request from "../request";

export interface GetQueryTaskIdResponse {
    message: string;
    data: {
        response: {
            task_id: string;
        };
    };
}

export const getQueryTaskId = (queryId: string) => request<GetQueryTaskIdResponse>({
    method: "GET",
    uri: `queries/${queryId}/run/`,
});
