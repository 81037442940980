import { ResultsTypes } from "../../types/queryResultsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: ResultsTypes,
}

export const getSelectedResult = (resultId: string) => request<ResponseType>({
    method: "GET",
    uri: `results/${resultId}/`,
});
