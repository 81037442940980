import {
    AfStatisticTypes,
    CoreStatisticTypes,
    PeriodicStatisticTypes,
    StatisticFiltersTypes,
} from "../../types/statisticTypes";
import request from "../request";

interface GetStatisticResponse {
    message: string,
    response: {
        core: CoreStatisticTypes;
        af: AfStatisticTypes;
        periodic: PeriodicStatisticTypes[];
    }
}

const getStatistic = (params: StatisticFiltersTypes) => request<GetStatisticResponse>({
    method: "GET",
    uri: "home/statistic/",
    params,
});

export default getStatistic;
