import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
    getTranslatedHandbook,
    queriesQueryPeriodHandbook,
    queriesRangeOptionsHandbook,
    queriesTypeOptionsHandbook,
} from "../../../../../constants/handbooks";
import { FieldMode } from "../../../../../constants/selectFields";
import { MAX_DESCRIPTION_FILED_LENGTH } from "../../../../../constants/validationRules";
import useTranslation from "../../../../../hooks/useTranslation";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import filtersTemplateStore from "../../../../../store/filters/templateFiltersStore";
import queriesStore from "../../../../../store/queries/queries/queriesStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { EditQueryType, QueriesTypes } from "../../../../../types/queriesTypes";
import DatePicker from "../../../../ui/datepicker/DatePicker";
import { SwitchButtonContainer } from "../../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../../ui/modal/modalStyle";
import { Title } from "../../../../ui/new/NewComponents";
import Switch from "../../../../ui/switch/Switch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import {
    fieldsList,
    getFieldStates,
    validationSchema,
} from "../../helpers/editQueryFormikHelpers";

const EditQueryModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id: queryId } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = queriesStore;

    const fieldsOptionsMap = {
        range: getTranslatedHandbook(translate, queriesRangeOptionsHandbook),
        query: getTranslatedHandbook(translate, queriesTypeOptionsHandbook),
        period: getTranslatedHandbook(translate, queriesQueryPeriodHandbook),
    };

    const fieldsDataMap = {
        partners: selectedItem?.partners.length ? selectedItem.partners.map((partner) => partner.id) : null,
        services: selectedItem?.services.length ? selectedItem?.services.map((service) => service.id) : null,
        processing: selectedItem?.processing?.id,
        start_time: selectedItem?.start_time,
    };

    const initialValues = {
        ...selectedItem,
        partners: fieldsDataMap.partners,
        services: fieldsDataMap.services,
        processing: fieldsDataMap.processing,
        start_time: fieldsDataMap.start_time,
    };

    const formik = useFormik<EditQueryType>({
        initialValues,
        validationSchema: validationSchema(t, translate),
        enableReinitialize: true,

        onSubmit: async (fields) => {
            if (queryId) {
                const partners = (fields.partners || []);
                const services = (fields.services || []);

                const fieldsData = { ...fields, partners, services };

                await queriesStore.patchSelectedItem(fieldsData as QueriesTypes, queryId, fieldsList);

                if (queriesStore.isEditItemSuccess) {
                    setOn(!isOpen);
                    snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
                } else if (editItemErrors?.common) {
                    snackbarStore.add({ variant: "error", text: t.snackbar.editError });
                }
            }
        },
    });

    const fieldStates = getFieldStates(formik.values, queriesStore.loading);

    const toggleQueryStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleChangeType = (value: unknown) => {
        if (value === "manual" || !value) {
            formik.setFieldValue("period", "", false);
            formik.setFieldValue("start_time", null, false);
        }
    };

    const handleCloseModal = () => {
        queriesStore.unsetEditState();
        formik.resetForm();
    };

    useEffect(() => {
        if (isOpen) {
            filtersPartnersStore.fetch();
            filtersServicesStore.fetch();
            filtersProcessingStore.fetch();
            filtersTemplateStore.fetch();
        }

        return () => handleCloseModal();
    }, [isOpen]);

    return (
        <Modal
            isForm
            gap="0"
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={queriesStore.loading}
            setOn={setOn}
            handleSubmit={formik.handleSubmit}
            handleClose={handleCloseModal}
        >
            <EditSectionWrapper $margin="32px 0">
                <FloatInput
                    formik={formik}
                    disabled={queriesStore.loading}
                    name="name"
                    isRequired
                    placeholder={t.queries.placeholders.name}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="template"
                    isRequired
                    disabled={queriesStore.loading}
                    placeholder={t.queries.placeholders.template}
                    options={filtersTemplateStore.options}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="date_range"
                    isRequired
                    disabled={queriesStore.loading}
                    placeholder={t.queries.placeholders.range}
                    options={fieldsOptionsMap.range}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="query_type"
                    isRequired
                    disabled={queriesStore.loading}
                    placeholder={t.queries.placeholders.type}
                    onChange={handleChangeType}
                    options={fieldsOptionsMap.query}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="period"
                    disabled={fieldStates.isPeriodAndDateFieldDisabled}
                    isRequired={!fieldStates.isPeriodAndDateFieldDisabled}
                    placeholder={t.queries.placeholders.queryPeriod}
                    options={fieldsOptionsMap.period}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <DatePicker
                    formik={formik}
                    name="start_time"
                    placeholder={t.queries.placeholders.queryStartTime}
                    isAddMilliseconds
                    disabled={fieldStates.isPeriodAndDateFieldDisabled}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="partners"
                    mode={FieldMode.Multiple}
                    isRequired={fieldStates.isPartnersFieldRequired}
                    disabled={fieldStates.isPartnersFieldDisabled}
                    placeholder={t.queries.placeholders.partner}
                    options={filtersPartnersStore.options}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="services"
                    mode={FieldMode.Multiple}
                    isRequired={fieldStates.isServicesFieldRequired}
                    disabled={fieldStates.isServicesFieldDisabled}
                    placeholder={t.queries.placeholders.service}
                    options={filtersServicesStore.options}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="processing"
                    isRequired={fieldStates.isProcessingFieldRequired}
                    disabled={fieldStates.isProcessingFieldDisabled}
                    options={filtersProcessingStore.options}
                    placeholder={t.queries.placeholders.processing}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={queriesStore.loading}
                    name="description"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    placeholder={t.queries.placeholders.description}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />

                <SwitchButtonContainer>
                    <Title>{t.status.title}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />

                    <Switch
                        disabled={queriesStore.loading}
                        checked={formik.values.is_active}
                        onChange={toggleQueryStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditQueryModal);
