import { ResultsFiltersTypes, ResultsTypes } from "../../types/queryResultsTypes";
import request from "../request";

type GetResultsRequest = ResultsFiltersTypes & {
    limit?: number;
    offset?: number;
    ordering?: string;
}

interface GetResultsResponse {
    message: string;
    response: {
        count: number;
        results: ResultsTypes[];
    };
}

export const getResults = (params?: GetResultsRequest) => request<GetResultsResponse>({
    method: "GET",
    uri: "results/",
    params,
});
