import { useState } from "react";

import { objectToValuesList } from "../../../../helpers";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import homeStore from "../../../../store/home/homeStore";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon } from "../../../ui/button/ButtonComponents";
import SmallButton from "../../../ui/button/SmallButton";
import ChartsFilters from "../components/ChartFilters/ChartsFilters";

export function useTabsActions() {
    const { t } = useTranslation();

    const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
    const toggleFilterModal = () => setFilterModalVisibility((prev) => !prev);

    const filtersCount = objectToValuesList(homeStore.filters || {}).length;

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            onClick={toggleFilterModal}
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const FilterModalComponent = (
        <FiltersModal
            isOpen={isFilterModalVisible}
            setOn={toggleFilterModal}
            width="400px"
            scrollable
        >
            <ChartsFilters closeModal={toggleFilterModal} />
        </FiltersModal>
    );

    const transactionsActionHeaderContentList = [
        { key: "filters-button-component", content: FiltersButtonComponent },
    ];

    return {
        FilterModalComponent,
        transactionsActionHeaderContentList,
    };
}
