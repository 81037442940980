import * as Yup from "yup";

import { MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import { TranslationsTypes } from "../../../../hooks/useTranslation";
import { NewListType } from "../../../../types/listsTypes";

const fieldsList: Array<keyof NewListType> = [
    "name",
    "rules_pack",
    "description",
    "is_active",
    "category",
    "type",
    "is_global",
    "alert",
    "processings",
];

const initialValues: NewListType = {
    name: "",
    description: "",
    is_active: true,
    alert: null,
    rules_pack: null,
    processings: null,
    category: null,
    type: null,
    is_global: null,
};

const validationSchema = (t: TranslationsTypes, translate: (text: string) => string) => (
    Yup.object({
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        category: Yup.string().required(t.validation.required),

        alert: Yup.string()
            .when("category", ([category], schema) => ((
                category === "Alert Exception"
            )
                ? schema.required(t.validation.required)
                : schema.nullable())),

        type: Yup.string()
            .when("category", ([category], schema) => ((
                category === "Other"
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),

        is_global: Yup.boolean()
            .when("category", ([category], schema) => ((
                category === "Other" || category === "Alert Exception"
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),

        rules_pack: Yup.number()
            .when(["is_global", "category"], ([is_global, category], schema) => ((
                is_global
                || (category === "Other")
                || (category === "Alert Exception")
                || is_global === null
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),

        processings: Yup.array()
            .when("is_global", ([is_global], schema) => ((
                is_global
            )
                ? schema.required(t.validation.required)
                : schema.nullable())),
    })
);

const getFieldStates = (formikValues: NewListType, listLoading: boolean) => {
    const { category, is_global } = formikValues;

    return {
        isOtherCategorySelected: category === "Other",
        isAlertExceptionSelected: category === "Alert Exception",
        isRulesPackSelectDisabled: is_global || is_global === null || category === "Alert Exception",
        isGlobalGroupSelected: is_global,

        isTypeDisabled: category === "Other" || listLoading,
        isGroupDisabled: category === "Other" || category === "Alert Exception" || listLoading,
        isRulesPackDisabled: is_global || is_global === null || category === "Alert Exception" || listLoading,

        isGroupRequired: category === "Other" || category === "Alert Exception",

        isGlobalGroupSelectedWithoutAlerts: !(category === "Alert Exception") && is_global,
    };
};

export {
    fieldsList,
    initialValues,
    validationSchema,
    getFieldStates,
};
