import { observer } from "mobx-react-lite";

import { getDeactivateAndActivateMenuItems } from "../../../../helpers/menuHelper";
import useTranslation from "../../../../hooks/useTranslation";
import { ActionModalType } from "../../../../types/actionModalTypes";
import MenuDropdown from "../../../ui/menuDropdown/MenuDropdown";

interface MenuProps {
    store: any;
    toggleActionModal: (type?: ActionModalType) => void;
}

const QueryMenu = ({ store, toggleActionModal }: MenuProps) => {
    const { translate } = useTranslation();

    const menuDropdownItemsList = getDeactivateAndActivateMenuItems(translate, toggleActionModal, store);

    return <MenuDropdown items={menuDropdownItemsList} />;
};

export default observer(QueryMenu);
