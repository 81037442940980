import { flow } from "mobx";

import { getResults } from "../../../api/results/getResults";
import { getSelectedResult } from "../../../api/results/getSelectedResult";
import { parseDateFromServer } from "../../../helpers";
import { ResultsFiltersTypes, ResultsTypes } from "../../../types/queryResultsTypes";
import dataStore from "../../common/dataStore";

class ResultsStore extends dataStore<ResultsTypes, ResultsFiltersTypes, any> {
    constructor() {
        super("ResultsStore");
    }

    protected fetchDataMethod = getResults;

    protected fetchSelectedItemMethod = getSelectedResult;

    fetchData = flow(function* fetchData(this: ResultsStore) {
        const response = yield this.fetchWithoutSet();

        if (response?.data.response.results) {
            this._data = parseDateFromServer<ResultsTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
            this._pagination.totalActiveCount = response.data.response.total_active_count;
        }
    });

    fetchSelectedItem = flow(function* fetchSelectedItem(this: ResultsStore, id: number | string) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);

        if (response?.data.response) {
            [this._selectedItem] = parseDateFromServer<ResultsTypes>(
                [response.data.response],
                "created",
            );
        }
    });
}

const resultsStore = new ResultsStore();

export default resultsStore;
