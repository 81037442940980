import { getQueries } from "../../../api/queries/filters/getQueries";
import { QueriesTypes } from "../../../types/queriesTypes";
import FiltersDataStore from "../../common/filtersStore";

class QueriesFiltersStore extends FiltersDataStore<QueriesTypes> {
    protected fetchListMethod = getQueries;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const filtersQueriesStore = new QueriesFiltersStore();

export default filtersQueriesStore;
