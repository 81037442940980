import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { FieldMode } from "../../../../constants/selectFields";
import { validateDateRange } from "../../../../helpers/validationHelper";
import useTranslation from "../../../../hooks/useTranslation";
import filtersPartnersStore from "../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../store/filters/processingFilterStore";
import filtersResultsStore from "../../../../store/filters/queries/resultsFiltersStore";
import filtersServicesStore from "../../../../store/filters/servicesFilterStore";
import resultsStore from "../../../../store/queries/results/resultsStore";
import { ResultsFiltersTypes } from "../../../../types/queryResultsTypes";
import {
    FormContainer,
    HeaderContainer,
    HeaderTitle,
    SubTitle,
} from "../../../filters/FiltersModalComponents";
import Button from "../../../ui/button/Button";
import { TextButton } from "../../../ui/button/ButtonStyles";
import DatePicker from "../../../ui/datepicker/DatePicker";
import FloatSelect from "../../../ui/textInput/FloatSelect";

interface ResultsFiltersProps {
    closeModal: () => void;
}

const ResultsFilters = ({ closeModal }: ResultsFiltersProps) => {
    const { t, translate } = useTranslation();

    const floatFieldsParamsList = [
        {
            key: "results-filters-id",
            name: "id",
            options: filtersResultsStore.options,
            placeholder: t.filters.fields.result,
        },
        {
            key: "results-filters-partners",
            name: "partners",
            options: filtersPartnersStore.options,
            placeholder: t.filters.fields.partner,
        },
        {
            key: "results-filters-services",
            name: "services",
            options: filtersServicesStore.options,
            placeholder: t.filters.fields.service,
        },
        {
            key: "results-filters-processings",
            name: "processings",
            options: filtersProcessingStore.options,
            placeholder: t.filters.fields.processing,
            mode: FieldMode.Multiple,
        },
    ];

    const initialValues: ResultsFiltersTypes = { ...resultsStore.filters };

    const formik = useFormik<ResultsFiltersTypes>({
        initialValues,
        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),
        initialTouched: {
            created_to: !!resultsStore.filters?.created_to,
        },
        enableReinitialize: true,

        onSubmit: (fields) => {
            resultsStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        resultsStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.results.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit}>
                {floatFieldsParamsList.map((params) => <FloatSelect formik={formik} {...params} />)}

                <SubTitle>{t.filters.fields.creationDate}</SubTitle>
                <DatePicker
                    formik={formik}
                    maxDate={formik.values.created_to}
                    name="created_from"
                    placeholder={t.filters.fields.from}
                />
                <DatePicker
                    formik={formik}
                    minDate={formik.values.created_from}
                    name="created_to"
                    placeholder={t.filters.fields.to}
                    isAddMilliseconds
                />

                <Button
                    variant="primary"
                    type="submit"
                    isLoading={resultsStore.loading}
                >
                    {t.filters.apply}
                </Button>
            </FormContainer>
        </>
    );
};

export default observer(ResultsFilters);
