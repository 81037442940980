import {
    Indicator,
    IndicatorContainer,
    MetricIndicatorContainer,
    MetricIndicatorTitle,
    MetricText,
    MetricTextContainer,
} from "./ProgressInfoComponents";

interface MetricIndicatorProps {
    status: "success" | "decline";
    statusTitle: string;
    value: number;
    percentValue: number;
}

const MetricIndicator = ({
    status, statusTitle, value, percentValue,
}: MetricIndicatorProps) => (
    <MetricIndicatorContainer>
        <IndicatorContainer>
            <Indicator $type={status} />
            <MetricIndicatorTitle>{statusTitle}</MetricIndicatorTitle>
        </IndicatorContainer>

        <MetricTextContainer>
            <MetricText>{value.toLocaleString("ru-RU")}</MetricText>
            <MetricText>{`${percentValue}%`}</MetricText>
        </MetricTextContainer>
    </MetricIndicatorContainer>
);

export default MetricIndicator;
