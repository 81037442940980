import type { Payload } from "recharts/types/component/DefaultLegendContent";
import {
    Box,
    Indicator,
    LI,
    UL,
    Title,
} from "./ChartLegendComponents";

interface ChartLegendProps {
    payload?: Payload[];
}

const ChartLegend = ({ payload }: ChartLegendProps) => (
    <Box>
        <UL>
            {payload && payload.map((item) => (
                <LI key={item.value}>
                    <Indicator $color={item.color} />
                    <Title>{item.value}</Title>
                </LI>
            ))}
        </UL>
    </Box>
);

export default ChartLegend;
