import FiltersDataStore from "../../common/filtersStore";
import { ResultsTypes } from "../../../types/queryResultsTypes";
import { getResult } from "../../../api/results/filters/getResults";

class ResultsFiltersStore extends FiltersDataStore<ResultsTypes> {
    protected fetchListMethod = getResult;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: item.id.toString(),
        }));
    }
}

const filtersResultsStore = new ResultsFiltersStore();

export default filtersResultsStore;
