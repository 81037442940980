import * as Yup from "yup";

import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import { TranslationsTypes } from "../../../../hooks/useTranslation";
import { NewQueryType } from "../../../../types/queriesTypes";

const fieldsList: Array<keyof NewQueryType> = [
    "name",
    "period",
    "start_time",
    "description",
    "services",
    "date_range",
    "template",
    "partners",
    "processing",
    "is_active",
    "query_type",
];

const initialValues: NewQueryType = {
    name: "",
    template: "",
    period: "",
    partners: null,
    services: null,
    processing: null,
    query_type: null,
    date_range: "",
    start_time: "",
    description: "",
    is_active: true,
};

const validationSchema = (t: TranslationsTypes, translate: (text: string) => string) => (
    Yup.object().shape({
        name: Yup.string()
            .required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        template: Yup.string().required(t.validation.required),
        date_range: Yup.string().required(t.validation.required),

        partners: Yup.array()
            .when(["services", "processing"], ([services, processing], schema) => (((
                services && services.length) || (processing && processing.length))
                ? schema.nullable()
                : schema.required(t.validation.required))),

        services: Yup.array()
            .when(["partners", "processing"], ([partners, processing], schema) => (((
                partners && partners.length) || (processing && processing.length)
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),

        processing: Yup.string()
            .when(["partners", "services"], ([partners, services], schema) => (((
                partners && partners.length) || (services && services.length)
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),

        query_type: Yup.string().required(t.validation.required),

        period: Yup.string()
            .when("query_type", ([query_type], schema) => (((
                query_type === "manual" || query_type === null
            ))
                ? schema.nullable()
                : schema.required(t.validation.required))),

        start_time: Yup.string()
            .when("query_type", ([query_type], schema) => (((
                query_type === "manual" || query_type === null
            ))
                ? schema.nullable()
                : schema.required(t.validation.required))),

        description: Yup.string()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    }, [["partners", "services"], ["partners", "processing"], ["services", "processing"]])
);

const getFieldStates = (formikValues: NewQueryType, queriesLoading: boolean) => {
    const {
        services, partners, processing, query_type,
    } = formikValues;

    return {
        isPartnersFieldRequired: !services && !processing,
        isServicesFieldRequired: !partners && !processing,
        isProcessingFieldRequired: !services && !partners,

        isPartnersFieldDisabled: queriesLoading || !!services || !!processing,
        isServicesFieldDisabled: queriesLoading || !!partners || !!processing,
        isProcessingFieldDisabled: queriesLoading || !!partners || !!services,

        isPeriodAndDateFieldDisabled: query_type === "manual" || query_type === null,
    };
};

export {
    fieldsList,
    initialValues,
    validationSchema,
    getFieldStates,
};
