import styled from "styled-components";

import { colors } from "../../../styles/variables";

const View = styled.div`
    display: flex;
    padding: 64px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    border: 2px dashed ${colors.inputDefaultBorder};
    background: ${colors.emptyTableBackground};

    h3, p {
        margin: 0;
    }
`;

const Title = styled.h3``;

const Text = styled.p``;

export {
    View,
    Title,
    Text,
};
