import { QueriesFiltersTypes } from "../../../types/queriesTypes";
import { ResultsTypes } from "../../../types/queryResultsTypes";
import request from "../../request";

type GetResultsRequest = QueriesFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetResultsResponse {
    message: string,
    response: {
        count: number
        results: ResultsTypes[];
    }
}

export const getResult = (params?: GetResultsRequest) => request<GetResultsResponse>({
    method: "GET",
    uri: "entities/filters/results/",
    params,
});
