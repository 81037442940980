import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import routes from "../../../../constants/routes";
import resultsStore from "../../../../store/queries/results/resultsStore";
import { ResultsTypes } from "../../../../types/queryResultsTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";

const columns: ColumnType<ResultsTypes>[] = [
    {
        header: "table.header.resultId",
        field: "id",
        padding: "13px 16px",
        cellStyles: { width: "0px", padding: "22px 16px" },
        getField: (dataItem) => (
            <TableMainLink to={`${routes.results.href}/${dataItem.id}`}>
                {dataItem.id}
            </TableMainLink>
        ),
    },
    {
        header: "table.header.message",
        field: "message",
        width: "180px",
        isResizable: true,
    },
    {
        header: "table.header.queryName",
        field: "query_name",
        width: "180px",
        isResizable: true,
    },
    { header: "table.header.resultQueryId", field: "query" },
    { header: "table.header.createdAt", field: "created" },
    {
        header: "table.header.partnerId",
        field: "partners",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => dataItem.partners.join(", "),
    },
    {
        header: "table.header.serviceId",
        field: "services",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => dataItem.services.join(", "),
    },
    {
        header: "table.header.processingId",
        field: "processing",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={routes.results.href} />
        ),
    },
];

const ResultsTable = () => {
    const tablePagination = {
        ...resultsStore.pagination,
        setPaginationPage: resultsStore.setPaginationPage,
        setPaginationSize: resultsStore.setPaginationSize,
    };

    useEffect(() => {
        resultsStore.fetchData();
    }, [resultsStore.pagination, resultsStore.filters, resultsStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            pagination={tablePagination}
            data={resultsStore.data}
            sortMode={resultsStore.ordering}
            loading={resultsStore.loading}
            checkedItems={resultsStore.checkedItems}
            onSort={resultsStore.toggleSort}
            onCheckboxChange={resultsStore.toggleItem}
            onMainCheckboxChange={resultsStore.toggleMainItem}
        />
    );
};

export default observer(ResultsTable);
