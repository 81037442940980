import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import { PeriodicStatisticTypes } from "../../../../../types/statisticTypes";
import { DetailsSectionWrapper } from "../../../../ui/details/DetailsComponents";
import {
    lineChartStyle,
    tooltipItemStyle,
    tooltipLabelStyle,
    tooltipViewStyle,
} from "./chartStyles";
import ChartLegend from "./ChartLegend/ChartLegend";
import ChartTick from "./ChartTick/ChartTick";

interface LineConfig {
    name: string;
    dataKey: string;
    stroke: string;
}

interface ChartProps {
    data: PeriodicStatisticTypes[];
    lines: LineConfig[];
}

const Chart = ({ data, lines }: ChartProps) => (
    <DetailsSectionWrapper>
        <ResponsiveContainer height={300} width="100%">
            <LineChart data={data} margin={lineChartStyle}>
                <CartesianGrid strokeOpacity={0.3} />

                <YAxis axisLine={false} />
                <XAxis
                    interval={0}
                    dataKey="date"
                    axisLine={false}
                    tick={<ChartTick />}
                />

                <Tooltip
                    contentStyle={tooltipViewStyle}
                    labelStyle={tooltipLabelStyle}
                    itemStyle={tooltipItemStyle}
                />

                <Legend
                    align="left"
                    verticalAlign="top"
                    content={<ChartLegend />}
                />

                {lines.map((line) => <Line {...line} dot={false} strokeWidth={2} />)}
            </LineChart>
        </ResponsiveContainer>
    </DetailsSectionWrapper>
);

export default Chart;
