/* eslint-disable camelcase */
import {
    ChangeEvent, FormEvent, useEffect, useState,
} from "react";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import { NewLimitType } from "../../../../types/limitsTypes";
import useTranslation from "../../../../hooks/useTranslation";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { removeEmptyFields } from "../../../../helpers/removeEmptyFields";
import limitStore from "../../../../store/limit/limitStore";
import SecondHeader from "../../../secondHeader/SecondHeader";
import StatusBadge from "../../../ui/textField/StatusBadge";
import Switch from "../../../ui/switch/Switch";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import {
    Box, Row, SubmitButtonWrapper, Title,
} from "../../../ui/new/NewComponents";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";
import {
    CodeContainer,
    CommonErrorFieldWrapper,
    Container,
    ContainerBody,
    FormContainerBody,
    FormWrapper,
} from "./NewLimitComponents";
import CodeEditor from "../../../ui/codeEditor/CodeEditor";
import Modal from "../../../ui/modal/Modal";
import CommandsModal from "../components/commandsModal/CommandsModal";
import rulesPacksFiltersStore from "../../../../store/filters/rulesPacksFiltersStore";
import Button from "../../../ui/button/Button";
import SmallButton from "../../../ui/button/SmallButton";
import { InfoIcon } from "../../../ui/button/ButtonComponents";
import ErrorLabel from "../../../ui/errors/ValidationErrorBox/ErrorLabel";
import { usePreventRedirect } from "../../../../hooks/usePreventRedirect";
import { useNavigation } from "../../../../hooks/useNavigation";
import { LocationState } from "../../../../types/locationTypes";

const fieldsList: Array<keyof NewLimitType> = [
    "name", "rules_pack", "description", "is_active", "channel_check", "code",
];

const NewLimit = () => {
    const { t, translate } = useTranslation();
    const { navigateBackward } = useNavigation();
    const state: LocationState = useLocation()?.state;
    const ruleId = state?.id;

    const [isCommandsModalVisible, setCommandsModalVisibility] = useState(false);

    const toggleCommandsModal = () => setCommandsModalVisibility((prev) => !prev);

    const handleNavigateToPrevPage = () => navigateBackward();

    const actionHeaderContentList = [
        {
            key: "cancel-button-component",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const validationSchema = Yup.object({
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        rules_pack: Yup.string().required(t.validation.required),
    });

    const initialValues: NewLimitType = {
        name: "",
        rules_pack: Number(ruleId) || null,
        description: "",
        channel_check: false,
        is_active: true,
        code: "",
    };

    const formik = useFormik<NewLimitType>({
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            const filteredData = removeEmptyFields(fields, "is_active", "channel_check");

            await limitStore.postData(filteredData, fieldsList);

            if (limitStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                limitStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.limits.snackbar.addSuccess });
            }
        },
    });

    const toggleLimitStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const toggleLimitType = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("channel_check", event.target.checked);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, limitStore.isPostRequestSuccess);

    useEffect(() => {
        rulesPacksFiltersStore.fetch();

        return () => limitStore.resetPostErrors();
    }, []);

    return (
        <>
            <SecondHeader title={t.limits.new.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <ContainerBody>
                    <Row $spacing>
                        <Title>{t.limits.new.title}</Title>

                        <Row>
                            <Title>{t.table.header.type}</Title>
                            <StatusBadge
                                variant={formik.values.channel_check ? "primary" : "inactive"}
                                multiLanguageText={t.table.header.channel}
                            />
                            <Switch
                                disabled={limitStore.loading}
                                checked={formik.values.channel_check}
                                onChange={toggleLimitType}
                            />
                            &nbsp;
                            <Title>{t.table.header.isActive}</Title>
                            <StatusBadge
                                variant={formik.values.is_active ? "success" : "inactive"}
                                multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                            />
                            <Switch
                                disabled={limitStore.loading}
                                checked={formik.values.is_active}
                                onChange={toggleLimitStatus}
                            />
                        </Row>
                    </Row>
                </ContainerBody>

                <FormWrapper onSubmit={handleSubmit}>
                    <FormContainerBody>
                        <Box>
                            <FloatInput
                                formik={formik}
                                disabled={limitStore.loading}
                                name="name"
                                isRequired
                                placeholder={t.limits.placeholders.name}
                                serverErrors={limitStore.postErrors}
                                resetServerError={limitStore.resetPostError}
                                isCommonError={limitStore.postErrors?.common}
                            />
                        </Box>
                        <Box>
                            <FloatSelect
                                formik={formik}
                                placeholder={t.limits.placeholders.rulesName}
                                isRequired
                                name="rules_pack"
                                disabled={!!ruleId || limitStore.loading}
                                options={rulesPacksFiltersStore.options}
                                serverErrors={limitStore.postErrors}
                                resetServerError={limitStore.resetPostError}
                                isCommonError={limitStore.postErrors?.common}
                            />
                        </Box>
                        <Box>
                            <FloatTextArea
                                formik={formik}
                                disabled={limitStore.loading}
                                name="description"
                                placeholder={t.placeholder.description}
                                serverErrors={limitStore.postErrors}
                                resetServerError={limitStore.resetPostError}
                                autoSize
                                maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                            />
                        </Box>
                        {limitStore.postErrors?.common && (
                            <CommonErrorFieldWrapper>
                                {ErrorLabel(limitStore.postErrors?.common)}
                            </CommonErrorFieldWrapper>
                        )}
                        <SubmitButtonWrapper>
                            <Button
                                variant="primary"
                                type="submit"
                                isLoading={limitStore.loading}
                            >
                                {t.buttons.add}
                            </Button>
                        </SubmitButtonWrapper>
                    </FormContainerBody>

                    <CodeContainer>
                        <Row $spacing>
                            <Title>{t.limits.text.code}</Title>
                            <div>
                                <SmallButton
                                    variant="tertiary"
                                    iconRight={<InfoIcon />}
                                    type="button"
                                    onClick={toggleCommandsModal}
                                >
                                    {t.limits.text.commands}
                                </SmallButton>
                            </div>
                        </Row>

                        <CodeEditor
                            name="code"
                            formik={formik}
                            disabled={limitStore.loading}
                            serverErrors={limitStore.postErrors}
                            resetServerError={limitStore.resetPostError}
                        />
                    </CodeContainer>
                </FormWrapper>
            </Container>

            <Modal
                gap="0"
                width="auto"
                submitButton={false}
                isOpen={isCommandsModalVisible}
                setOn={setCommandsModalVisibility}
            >
                <CommandsModal />
            </Modal>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewLimit);
